import $ from 'jquery'

const toggleActiveClass = (actionContainer) => {
  const $actionContainer = $(actionContainer)

  $actionContainer.toggleClass('active')
}

$(document).on(
  'click',
  '.section--collapsible-text__action-container a[data-toggle="collapse"]',
  (e) => toggleActiveClass(e.target)
)
